import './App.css';
import Home from './Pages/Home.tsx';

function App() {
  return (
    <Home />
  );
}

export default App;
