import React, {} from "react";
import { Button, Flex, Input, Text } from "@chakra-ui/react";
import FileDropper from "./FileDropper.tsx";
import { MdAttachFile } from "react-icons/md";

const UploadFile = () => {
  const [isHovered, setIsHovered] = React.useState<boolean>(false);
  // const [file, setFile] = React.useState<File | null>(null);

  return (
    <Flex 
      w={"45%"} 
      h={"75%"}
      gap={12}
      borderRadius={"12px"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"} 
      border={"2px solid rgba(255, 255, 255, 0.05)"}
      _hover={{ border: "2px solid rgba(255, 255, 255, 0.2)" }}
    >
      <Text>
        Aşağıdaki buton aracılığıyla bir dosya yükleyebilirsiniz.
      </Text>
      <FileDropper/>
      <Text>
        ya da
      </Text>
      <Button
        style={
          {
            position: "relative",
            width: "20%",
            height: "10%",
            borderRadius: "12px",
            background: isHovered ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.05)",
            color: isHovered ? "black" : "white",
            outline: "none",
            border: "none",
            fontFamily: "Poppins, sans-serif",
            fontSize: "12px",
            fontWeight: 700,
            transform: isHovered ? "scale(1.1)" : "scale(1)",
            transition: "transform 0.5s",
          }
        }

        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Input
          position="absolute"
          opacity={0}
          type="file"
          onChange={(event) => {
            if (event.target.files === null) return;
            if (event.target.files.length > 1) {
              alert("You can only upload one file at a time.");
              return;
            }
            // setFile(event.target.files[0]);
          }}
        />
        <MdAttachFile size={20}/> &nbsp; Bir dosya seçin
      </Button>
    </Flex>
  );
};

export default UploadFile;