import React, {} from "react";
import { Flex, Text, Image } from "@chakra-ui/react";
import UploadFile from "./Components/UploadFile.tsx";
// import ProcessFile from "./Components/ProcessFile.tsx";
const Home = () => {
  return (
    <Flex justifyContent="center" alignItems="center"
      style={
        {
          height: "100vh",
          width: "100%",
          background: "url('https://i.ibb.co/mcDnzH9/tebex.png')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          color: "white",
        }
      }
    >
      <Flex alignItems="flex-start" flexDirection={"column"}
      style={
        {
          height: "90vh",
          width: "95%",
          color: "white",
          borderRadius: "12px",
        }
      }>
        <Flex className="header" alignItems="center" style={{ width: "100%"}}>
          <Image
            src="https://files.catbox.moe/fv800m.png"
            w={128}
          />
          <Text fontSize={36} fontWeight={700}>CACTUS</Text>
        </Flex>
        <Flex
          style={{
            width: "100%",
            height: "80%",
          }}
          flexDirection={"row"} gap={24} justifyContent={"space-between"} alignItems={"center"}>
          <UploadFile />
          <UploadFile />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Home;