import React, { } from "react";
import { Flex, Text, Button } from "@chakra-ui/react";
import { FileDrop } from 'react-file-drop';
import { MdAttachFile } from "react-icons/md";
import { FaClipboard } from "react-icons/fa";

import axios from "axios";

const API_URL = "https://temp.cactusrp.com";

const FileDropper = () => {
  const [isDragged, setIsDragged] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingText, setLoadingText] = React.useState<string>("");
  const [url, setUrl] = React.useState<string>("");
  async function upload(file: File): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    console.log("uploading file", file);

    try {
      const data = await axios.post(`${API_URL}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      console.log("file uploaded", data);
      setLoadingText("Dosya yüklendi!");
      setUrl(data.data.url);
      return data.data.url;
    } catch (error) {
      console.error("error uploading file", error);
      setLoadingText("Dosya yüklenemedi!");
      setUrl("");
      return null;
    }
  }

  return (
    <Flex
      w={"50%"}
      h={"25%"}
      background={isDragged ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0.2)"}
      borderRadius={"12px"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      border={ isDragged ? "2px dashed rgba(255, 255, 255, 0.5)" : "2px dashed rgba(255, 255, 255, 0.2)"}
      userSelect={"none"}
      _loading={{ border: "2px dashed rgba(255, 255, 255, 0.5)" }}
    >
      <FileDrop
        onFrameDragEnter={(event) => setIsDragged(true)}
        onFrameDragLeave={(event) => setIsDragged(false)}
        onDrop={(files, event) => {
          setIsDragged(false);

          if(files === null) return;

          if (files.length > 1) {
            alert("You can only upload one file at a time.");
            return;
          }

          setLoading(true);
          setLoadingText("Dosya yükleniyor...");
          upload(files[0]);
        }}
      >
        <Flex justifyContent={"center"} alignItems={"center"} flexDirection={"column"} _loading={
          {
            opacity: 0.5,
            cursor: "not-allowed"
          }
        }>
          {loading && (
            <Text>
              {loadingText}

              {url && (
                <Button
                  style={
                    {
                      outline: "none",
                      border: "none",
                      background: "transparent",
                      color: "white",
                      cursor: "pointer",
                    }
                  }
                  onClick={() => {
                    navigator.clipboard.writeText(url);
                  }}
                >
                  <FaClipboard />
                </Button>
              )}
              
            </Text>
          )} 
          
          {!loading && (
            <Flex justifyContent={"center"} alignItems={"center"} gap={4}>
              <MdAttachFile />
              <Text lineHeight={0} fontSize={16} fontWeight={700}>Dosyayı buraya sürükleyebilirsiniz</Text>
            </Flex>
          )}

        </Flex>
      </FileDrop>
    </Flex>
  );
};

export default FileDropper;